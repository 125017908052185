<template>
  <article class="view-register">
    <div class="site-wrapper rythm-v">
      <header class="page-header">
        <h1>Inscription</h1>
      </header>

      <section class="section--register rythm-v">
        <header>
          <h2>Informations professionnelles</h2>
        </header>

        <div class="form-wrapper card shadow">
          <form method="post" @submit.prevent="registerUser">
            <div class="flex-row">
              <div class="flex-item--50">
                <div class="input-group required">
                  <label for="firstname">Prénom</label>
                  <input type="text" name="firstname" id="firstname" v-model="registerData.firstname" required />
                </div>
              </div>

              <div class="flex-item--50">
                <div class="input-group required">
                  <label for="lastname">Nom</label>
                  <input type="text" name="lastname" id="lastname" v-model="registerData.lastname" required />
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div class="flex-item--30 flex-item--grow flex-row flex-align-top avatar-wrapper">
                <div class="input-group input-avatar">
                  <label for="avatar" :class="validations.avatar.invalid ? 'invalid' : ''"
                    >Photo / Avatar <small>{{ validations.avatar.error }}</small></label
                  >
                  <input type="file" name="avatar" id="avatar" ref="avatar" accept="image/*" :invalid="validations.avatar.invalid" @change="setAvatarPreview" />
                </div>
                <img v-if="avatarPreviewSrc && !validations.avatar.invalid" :src="avatarPreviewSrc" class="avatar-preview" alt="Avatar" width="128" height="auto" />
              </div>

              <div class="flex-item--30 flex-item--grow">
                <p class="label required">Pays</p>
                <div class="input-group">
                  <select name="country" id="country" required v-model="registerData.country">
                    <option value="">Aucune sélection</option>
                    <option v-for="(country, index) in lists.countries" :key="`country_${index}`" :value="country.code" :selected="country.code === 'FR'">{{ country.name }}</option>
                  </select>
                </div>
              </div>

              <div class="flex-item--100 flex-row flex-align-top" v-if="registerData.country === 'FR'">
                <div class="flex-item--50">
                  <p class="label required">Identifiant professionnel <span class="small">(soumis à vérification)</span></p>
                  <div class="input-group">
                    <div class="input-group--inline input-group-radio">
                      <label>
                        <input type="radio" name="pro_id" value="rpps" v-model="pro_id" required />
                        <span class="caption">R.P.P.S.</span>
                      </label>
                      <label>
                        <input type="radio" name="pro_id" value="adeli" v-model="pro_id" required />
                        <span class="caption">ADELI</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="flex-item--50" v-if="pro_id == 'adeli'">
                  <div class="input-group">
                    <label class="label-adeli" for="adeli">
                      <span class="caption">N° ADELI</span>
                      <span
                        class="info"
                        v-tooltip="{
                          content: tooltip_adeli,
                          placement: 'top-center',
                          classes: ['info'],
                          delay: {
                            show: 100,
                            hide: 500,
                          },
                          offset: 10,
                          trigger: 'hover focus click',
                        }"
                        ><svg class="icon icon-info" role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                            clip-rule="evenodd"
                          />
                          <path fill="currentColor" d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" />
                        </svg>
                      </span>
                    </label>
                    <input
                      type="text"
                      name="adeli"
                      id="adeli"
                      v-model="registerData.adeli"
                      pattern="^([a-zA-Z0-9]{9})$"
                      minlength="9"
                      maxlength="9"
                      placeholder="Exemple : 123456782"
                      :class="adeliValidates"
                      :required="this.pro_id === 'adeli'"
                    />
                  </div>
                </div>

                <div class="flex-item--50" v-if="pro_id == 'rpps'">
                  <div class="input-group">
                    <label class="label-rpps" for="rpps">
                      <span class="caption">N° R.P.P.S</span>
                      <span
                        class="info"
                        v-tooltip="{
                          content: tooltip_rpps,
                          placement: 'top-center',
                          classes: ['info'],
                          delay: {
                            show: 100,
                            hide: 500,
                          },
                          offset: 10,
                          trigger: 'hover focus click',
                        }"
                        ><svg class="icon icon-info" role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                            clip-rule="evenodd"
                          />
                          <path
                            fill="currentColor"
                            fill-rule="evenodd"
                            d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                            clip-rule="evenodd"
                          />
                          <path fill="currentColor" d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" />
                        </svg>
                      </span>
                    </label>
                    <input
                      type="text"
                      name="rpps"
                      id="rpps"
                      v-model="registerData.rpps"
                      pattern="^(\d{11})$"
                      minlength="11"
                      maxlength="11"
                      placeholder="Exemple : 10000123456"
                      :class="rppsValidates"
                      :required="this.pro_id === 'rpps'"
                    />
                    <!-- 9 OR 11 pattern="^[0-9]{9}([0-9]{2})?$" -->
                  </div>
                </div>
              </div>

              <!-- <div
                class="flex-item--30 flex-item--grow"
                v-if="registerData.country === 'FR'"
              >
                <div class="input-group required">
                  <label class="label-rpps" for="rpps">
                    <span class="caption">N° R.P.P.S</span>
                    <span class="small">(soumis à vérification)</span>
                    <span
                      class="info"
                      v-tooltip="{
                        content: tooltip_rpps,
                        placement: 'top-center',
                        classes: ['info'],
                        delay: {
                          show: 100,
                          hide: 500,
                        },
                        offset: 10,
                        trigger: 'hover focus click',
                      }"
                      ><svg
                        class="icon icon-info"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="22"
                        fill="none"
                        viewBox="0 0 22 22"
                      >
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                          clip-rule="evenodd"
                        />
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                          clip-rule="evenodd"
                        />
                        <path
                          fill="currentColor"
                          d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z"
                        />
                      </svg>
                    </span>
                  </label>
                  <input
                    type="text"
                    name="rpps"
                    id="rpps"
                    v-model="registerData.rpps"
                    pattern="^(\d{11})$"
                    required
                    placeholder="Exemple : 10000123456"
                  />

                </div>
              </div> -->
            </div>

            <div class="flex-row">
              <div class="flex-item--30">
                <p class="label required">Spécialité</p>
                <div class="input-group">
                  <select name="specialty" id="specialty" required v-model="registerData.specialty">
                    <option value="">Aucune sélection</option>
                    <option v-for="(specialty, index) in lists.specialties" :key="`specialty_${index}`" :value="specialty">{{ specialty }}</option>
                  </select>
                </div>
              </div>

              <div class="flex-item--30">
                <div class="input-group required">
                  <label for="city">Ville d'exercice</label>
                  <input type="text" name="city" id="city" v-model="registerData.city" required />
                </div>
              </div>

              <div class="flex-item--30">
                <div class="input-group required">
                  <label for="zip">Code postal</label>
                  <input type="text" name="zip" id="zip" v-model="registerData.zip" required />
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div class="flex-item--50">
                <p class="label required">Type d'exercice</p>
                <div class="input-group">
                  <select name="practices" id="practices" required v-model="registerData.practice">
                    <option value="">Aucune sélection</option>
                    <option v-for="(practice, index) in lists.practices" :key="`practice_${index}`" :value="practice">{{ practice }}</option>
                  </select>
                </div>
              </div>

              <div class="flex-item--50">
                <div class="input-group">
                  <label for="practice_other">Autre type d'exercice</label>
                  <input type="text" name="practice_other" id="practice_other" v-model="registerData.practice_other" />
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div class="flex-item--50">
                <p class="label required">
                  Comment nous avez-vous connu&nbsp;?
                </p>
                <div class="input-group--inline input-group-radio">
                  <label v-for="(referrer, index) in lists.referrers" :key="`referrer_${index}`">
                    <input type="radio" name="referrer" :value="referrer" v-model="registerData.referrer" required />
                    <span class="caption">{{ referrer }}</span>
                  </label>
                </div>
              </div>

              <div class="flex-item--50" v-if="registerData.referrer === 'Industrie'">
                <p class="label required">
                  Par quelle société ?
                </p>
                <div class="input-group--inline input-group-radio">
                  <label v-for="(referral, index) in lists.referrals" :key="`referral_${index}`">
                    <input type="radio" name="referral" :value="referral" v-model="registerData.referral" required />
                    <span class="caption">{{ referral }}</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div class="flex-item--100">
                <p class="label required">
                  Souhaitez-vous recevoir la newsletter mensuelle Crossdoc (Actualités, congrès, questions les plus intéressantes…)&nbsp;?
                </p>
                <div class="input-group--inline input-group-radio">
                  <label for="newsletter-yes">
                    <input type="radio" id="newsletter-yes" name="newsletter" value="1" v-model="registerData.newsletter" required />
                    <span class="caption">Oui</span>
                  </label>
                  <label for="newsletter-no">
                    <input type="radio" id="newsletter-no" name="newsletter" value="0" v-model="registerData.newsletter" required />
                    <span class="caption">Non</span>
                  </label>
                </div>
              </div>
            </div>

            <div class="flex-row">
              <div class="input-group--controls flex-item--100">
                <button type="submit" class="button--primary" :disabled="submitButtonDisabled">
                  <svg class="icon icon-check" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
                    <path
                      fill="currentColor"
                      fill-rule="evenodd"
                      d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <span class="caption">Enregister</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/register-profile.scss";
</style>

<script>
import { appConfig } from "../config.js";
import { HTTP } from "../http-common";
import { EventBus } from "../event-bus.js";
import countries from "../libs/countries.json";

export default {
  name: "register",

  data: function() {
    return {
      user: null,
      fileInput: null,
      avatarPreviewSrc: "",
      pro_id: "",
      // radio/selects lists
      lists: {
        countries: countries,
        specialties: [
          "C.C.A.",
          "Diététicien",
          "Gastro-entérologue adultes",
          "Gastro-Pédiatre",
          "Infirmier",
          "Interne",
          "Kinésithérapeute",
          "Médecin généraliste",
          "Médecin P.M.I.",
          "Orthophoniste",
          "Pédiatre",
          "Pharmacien",
          "Psychomotricien",
          "Sage-femme",
          "Autre",
        ],
        practices: ["Cabinet", "Cabinet de groupe", "Clinique", "Exercice mixte", "Hospitalier", "Hospitalo-universitaire", "PMI", "Autre"],
        referrers: ["Congrès", "Industrie", "Bouche à oreille", "Moteur de recherche", "Autre"],
        referrals: ["Biocodex", "Bledina", "Guigoz", "Mendes", "Novalac", "Nutribio"],
      },

      validations: {
        avatar: {
          invalid: false,
          error: "(image de 1Mo maximum)",
        },
      },

      registerData: {
        avatar: "",
        city: "",
        firstname: "",
        lastname: "",
        practice_other: "",
        practice: "",
        referrer: "",
        referral: "",
        country: "FR",
        rpps: "",
        adeli: "",
        specialty: "",
        newsletter: "",
      },

      tooltip_adeli: "<strong>Le numéro ADELI est composé de 9 caractères.</strong>",
      tooltip_rpps: "<strong>Le numéro R.P.P.S. est composé de 11 caractères.</strong>",

      submitButtonDisabled: false,
    };
  },

  computed: {
    rppsValidates() {
      if (!this.registerData.rpps || !this.registerData.rpps.length) return;
      let isValid = false;
      if (this.registerData.rpps.length !== 11) isValid = false;
      else isValid = true;
      return isValid ? "is-valid" : "is-invalid";
    },

    adeliValidates() {
      if (!this.registerData || !this.registerData.adeli) return;
      let isValid = false;
      if (this.registerData.adeli.length !== 9) isValid = false;
      else isValid = true;

      console.log("this.registerData.adeli.length", this.registerData.adeli.length);

      return isValid ? "is-valid" : "is-invalid";
    },

    subscriptionNeeded() {
      return this.registerData.country !== "FR";
    },

    isTempRegisterData() {
      return parseInt(this.registerData.is_temp_data);
    },
  },

  watch: {
    // registerData.referrer: resets registerData.referral if not "Industrie"
    "registerData.referrer": {
      handler: function(after, before) {
        // value reset (except for temp data)
        if (after !== "Industrie" && !this.isTempRegisterData) {
          this.registerData.referral = "";
        }
      },
    },

    // Reset RPPS / ADELI
    pro_id: {
      handler: function(after, before) {
        // value reset
        if (after === "rpps") {
          this.registerData.adeli = "";
        }
        if (after === "adeli") {
          this.registerData.rpps = "";
        }
      },
    },
  },

  async mounted() {
    // Current user data
    this.user = this.$store.state.userData;

    // Load profile (if any)
    this.loadUserData();

    // Load & Configure Stripe JS
    this.includeStripe(
      function() {
        this.configureStripe();
      }.bind(this)
    );

    // User not logged in
    if (null === this.user) {
      let successMsg = this.$toasted.global.appSuccess({
        message: "Votre adresse e-mail est vérifiée !",
      });
      successMsg.goAway(4000);
      this.$toasted.global.appInfo({
        message: "Connectez-vous pour compléter votre profil et terminer votre inscription.",
      });
      return this.$router.push("/login");
    }

    // Registration completed already
    else {
      if (parseInt(this.user.registration_complete) === 1) {
        //  TODO: UNCOMMENT! // return this.$router.push("/dashboard");
      }
    }

    this.registerData.user_id = this.user.id;
  },

  methods: {
    toggleSubmit(status) {
      this.submitButtonDisabled = status;
    },

    // Load user data from database (if any: database imports, manual creation, etc…)
    loadUserData: function() {
      this.$store
        .dispatch("GET_USER_DATA", this.user.id)
        .then((response) => {
          if (!response || !response.id) return;

          this.registerData = response;
          // Set avatar preview
          if (response.avatar) this.avatarPreviewSrc = response.avatar;
          // Do NOT set the email!
          this.registerData.email = null;
        })
        .catch((error) => {
          console.warn(error);
        });
    },

    // Load and Configure Stripe
    includeStripe(callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//js.stripe.com/v3/";
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },

    configureStripe() {
      this.stripe = Stripe(appConfig.STRIPE_PUBLIC_API_KEY);
    },

    /**
     * Checkout Session pour l'abonnement CrossDOC (PriceID)
     */
    createCheckoutSession: function() {
      this.$store
        .dispatch("STRIPE_CHECKOUT_SESSION", {
          userID: this.registerData.user_id,
        })
        .then((payload) => {
          // Call Stripe.js method to redirect to the new Checkout page
          this.stripe
            .redirectToCheckout({
              sessionId: payload.sessionId,
            })
            .then(function(data) {
              console.log({ data });
            });
        })
        .catch((error) => {
          let msg = "Une erreur est survenue !";
          return this.$toasted.global.appError({
            message: msg,
          });
        });
    },

    setCountry: function(e) {
      this.registerData.country = e.target.value;
    },
    setSpecialty: function(e) {
      this.registerData.specialty = e.target.value;
    },
    setPractice: function(e) {
      this.registerData.practice = e.target.value;
    },

    // avatar preview
    setAvatarPreview: function(e) {
      let fileInput = e.target;
      if (fileInput.files && fileInput.files[0]) {
        this.validations.avatar.invalid = false;
        this.validations.avatar.error = "(image de 1Mo maximum)";

        // image preview
        let blob = URL.createObjectURL(fileInput.files[0]);
        if (blob) this.avatarPreviewSrc = blob;

        // file type
        if (fileInput.files[0].type.indexOf("image/") === -1) {
          this.validations.avatar.invalid = true;
          this.validations.avatar.error = "(Le fichier n'est pas une image)";
        }

        // file weight
        if (typeof FileReader !== "undefined") {
          let size = fileInput.files[0].size;
          if (size > 1000000) {
            this.validations.avatar.invalid = true;
          }
        }
      }
    },

    // Create user profile
    registerUser: function() {
      // Disable the submit button
      this.toggleSubmit(true);

      // Check Adeli
      if (this.pro_id === "adeli" && !this.adeliValidates) {
        // Enable the submit button
        this.toggleSubmit(false);
        return this.$toasted.global.appError({
          message: "Le format du numéro ADELI est incorrect",
        });
      }

      let userID = this.user.id;
      this.registerData.user_id = userID;

      let fileInput = this.$refs.avatar.files[0];
      let formData = new FormData();

      formData.append("file", fileInput);
      formData.append("data", JSON.stringify(this.registerData));

      this.$store
        .dispatch("INSERT_USER_DATA", { userID, formData })
        .then(() => {
          // Redirect to dashboard
          EventBus.$emit("trackEvent", "Inscription", "Profil complété");
          this.$router.push("/dashboard");
        })
        .catch((error) => {
          // Enable the submit button
          this.toggleSubmit(false);

          let msg = "Une erreur est survenue lors de la création de votre profil.<br>Veuillez nous contacter pour résoudre le problème.";

          if (error.status === 409) {
            msg = "Cette adresse e-mail est déjà utilisée&nbsp;!";
          }

          EventBus.$emit("trackEvent", "Inscription", `Erreur enregistrement du profile (${error.status})`);
          return this.$toasted.global.appError({
            message: msg,
          });
        });
    },
  },
};
</script>
